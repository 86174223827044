<template>
  <v-list-item three-line>
    <v-list-item-avatar class="hidden-xs-only">
      <computer-role-icon :value="computer.role"/>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title class="d-flex flex-row align-center">
        {{ computer.role | computerRoleLabel}}&nbsp;<span class="caption">— <os-icon :os="computer.parsedData.software.os"/> {{computer.parsedData.name}}</span>
        <v-tooltip bottom v-if="computer.dynamicComputerData.obsolete">
          <template v-slot:activator="{on, attrs}">
            <v-icon v-on="on" v-bind="attrs" class="ml-1" small color="error">mdi-alert</v-icon>
          </template>
          Machine obsolète, cet ordinateur doit être remplacé par du matériel plus récent pour bénéficier des mises à jour de Yodaforex.
        </v-tooltip>

        <span class="ml-2 caption">— <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{ computer.lastPing | moment('from') }}</span>
          </template>
          <span>{{ computer.lastPing | moment('LLLL') }}</span>
        </v-tooltip></span>

      </v-list-item-title>
      <v-list-item-subtitle>
        <span
            v-if="computer.dynamicComputerData.modelName">{{ computer.dynamicComputerData.modelName }} — </span>{{ computer.parsedData.hardware.cpuBrand }}
        {{ computer.parsedData.hardware.cpuReference }} {{ computer.parsedData.hardware.cpuCoreCount }} coeurs —
        {{ computer.parsedData.hardware.physicalMemory | humanReadableBytes }} RAM
      </v-list-item-subtitle>
      <v-list-item-subtitle>

        <v-tooltip v-if="computer.dynamicComputerData.needsSoftwareUpdate" bottom>
          <template v-slot:activator="{on,attrs}">
            <span v-bind="attrs" v-on="on" class="text-warning">{{ computer.parsedData.software.os | formatOS }} {{ computer.parsedData.software.osVersion | formatVersion }}</span>
          </template>
          Une mise à jour du système d'exploitation est nécessaire
        </v-tooltip>
        <span v-else>{{ computer.parsedData.software.os | formatOS }} {{ computer.parsedData.software.osVersion | formatVersion }}</span>
        <span v-bind:class="{'text-warning': computer.dynamicComputerData.needsSoftwareUpdate}">

        </span>
        <span v-if="computer.dynamicComputerData.newestCompatibleOs"> — version max de macOS : {{ computer.dynamicComputerData.newestCompatibleOs.full }}</span>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action v-if="computer.parsedData.hardware.mainStorage">
      <computer-storage-status :computer="computer"/>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import convertBytesToHumanReadable from 'human-readable-bytes'
import OsIcon from "@/components/OsIcon";
import ComputerRoleIcon from "@/components/ComputerRoleIcon.vue";
import ComputerStorageStatus from "@/components/ComputerStorageStatus.vue";

export default {
  name: "ComputerListItem",
  components: {ComputerStorageStatus, ComputerRoleIcon, OsIcon},
  props: ['computer'],
  filters: {
    humanReadableBytes(v, method = 1024) {
      return convertBytesToHumanReadable(v, method, 0, false)
    },
    formatVersion(v) {
      let r = v.major;
      if (v.minor) r+="."+v.minor;
      if (v.fix) r+="."+v.fix;
      return r;
    },

    computerRoleLabel(v) {
      if (v === "SERVER") return "Serveur";
      if (v === "PILOT") return "Licence Pilote";
      if (v === "CLIENT") return "Guichet";
      if (v === "MONO") return "Guichet mono-poste"
      else return "Poste";
    },
    formatOS(v) {
      if (v === "MACOS") return "macOS";
      if (v === "WINDOWS") return "Windows";
      else return "";
    }
  }
}
</script>

<style scoped>
.text-warning {
  font-weight: 500;
  color: rgb(251,140,0) !important;
}
</style>
